/* Primary: #0a2540; Secondary: #1976d2; Tertiary: #00d4ff #0008ff #0560fa #00d4ff  */
.App a {
  color: inherit;
}

.league-table-links {
  text-decoration: none;
  color: #ffffff;
}

.accordian-table-links {
  color: #000000;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-italic {
  font-style: italic;
}

.team-fixtures {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.xl-title {
  font-size: 50px;
}

.player-overall-stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: center;
}

.player-circle-stats {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-bio-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.player-bio {
  max-width: 500px;
}

.player-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
}

.text-overlay {
  background: #0c2e4e;
  color: #06d3ff;
  font-size: 50px;
  width: fit-content;
  text-align: left;
  padding: 10px;
  margin: 0;
}

h2.text-overlay {
  font-size: 25px;
}

.text-overlay-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

ol {
  list-style-type: upper-roman;
  line-height: 1.55;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.bullet-list {
  line-height: 1.55;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

blockquote {
  background: #f2f2f4;
  border-left: 10px solid #ccc;
  margin: 2.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.primary-background {
  background: #0a2540;
}

.match-report-container {
  width: 75%;
  position: relative;
  bottom: 15vh;
  z-index: 2;
  margin: auto;
}

.match-report {
  padding: 5% 10%;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  color: #4a4a4a;
  text-align: left;
}

.match-report h1 {
  line-height: 60px;
  font-weight: 100;
}

.match-report h2 {
  line-height: 30px;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  margin: 25px 0;
}

.match-table {
  padding: 0;
}

.match-table td {
  text-align: center;
  padding: 0;
  width: 100px;
}

.match-logos {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: 10vh;
}

.match-logos img {
  height: 160px;
  width: 160px;
}

.match-logos img {
  border-radius: 10px;
  border: 1px solid #4a4a4a;
}

.secondary-background {
  background: #f2f2f4;
}

.tertiary-background {
  background: #0c2e4e;
}

.quaternary-background {
  background: #f6f9fc;
}

.button-primary {
  background: #00d4ff !important;
  color: #0a2540 !important;
  font-weight: 700 !important;
}

@property --size {
  syntax: '<percentage>';
  inherits: false;
  initial-value: -150%;
}

@property --color {
  syntax: '<color>';
  inherits: false;
  initial-value: #00d4ff;
}

@keyframes pulse {
  from {
    --color: #00d4ff;
    --size: -210%
  }

  to {
    --color: #0560fa;
    --size: -100%
  }
}

.footer-diagonal-container {
  background: #f6f9fc;
}

.diagonal-outer-white {
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.diagonal-outer-grey {
  background: #f6f9fc;
  display: flex;
  flex-direction: column;
}

.diagonal-inner {
  --size: -210%;
  --color: #00d4ff;
  background: radial-gradient(var(--color) var(--size), #0a2540 70%);
  animation: pulse 20s infinite alternate;
  min-height: 600px;
  color: #f2f2f4;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 69px));
  z-index: 2;
  padding: 100px 20%;
  text-align: left;
}

.diagonal-inner h1 {
  padding-bottom: 25px
}

.diagonal-inner p {
  font-size: 22px;
  font-weight: 100;
}

.article-container {
  margin: 150px 20%;
  text-align: left;
}

.article-title {
  margin-bottom: 50px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  color: #4a4a4a;
}

.article-title h1 {
  line-height: 60px;
  color: #4a4a4a;
  font-weight: 100;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  margin-bottom: 25px;
}

.article-title h2 {
  line-height: 30px;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  margin-bottom: 50px;
}

.author {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 18px;
  color: #1976d2;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.author-role {
  margin-bottom: 50px;
  font-size: 17px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.date {
  font-size: 17px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  color: #4a4a4a;
}

.clock {
  margin-right: 5px;
}

.article-image {
  margin-bottom: 50px;
}

.article-content {
  margin-top: 25px;
  margin-bottom: 50px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.article-content h2 {
  line-height: 30px;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  margin: 20px 0;
}

.article-content h3 {
  line-height: 30px;
  font-size: 30px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  margin: 30px 0;
}

.news-article-link {
  line-height: 60px;
  font-size: 25px;
  color: #4a4a4a;
  font-weight: 200;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.article-content p {
  line-height: 1.55;
  font-size: 22px;
  color: #4a4a4a;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.article-content h5 {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  color: #4a4a4a;
}


.App {
  text-align: center;
  background: #f6f9fc;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
  color: #4a4a4a;
}

.App-logo-contact {
  width: 400px;
  height: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  position: relative;
  bottom: 75px;
  z-index: 1;
}

.brand-title,
.brand-title-blue {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

.brand-title {
  color: #4a4a4a;
}

.brand-title-blue {
  color: #1976d2;
}

.brand-title h1,
.brand-title-blue h1 {
  line-height: 60px;
  font-weight: 100;
  margin-bottom: 25px;
}

.brand-title h1 {
  color: #4a4a4a;
}

.brand-title-blue h1 {
  color: #1976d2;
}

.brand-title-light-blue h1 {
  color: #00d4ff;
  line-height: 60px;
  font-weight: 200;
}

.brand-title h2 {
  line-height: 50px;
  font-weight: 100;
  margin-bottom: 25px;
}

.brand-content {
  margin-bottom: 50px;
}

.brand-content p {
  line-height: 1.55;
  font-size: 22px;
  color: #4a4a4a;
}

.brand-content-2 p {
  line-height: 1.55;
  font-size: 22px;
  color: #ffffff;
  margin: 50px 0;
}

.bottom-nav {
  background: #0c2e4e;
  position: fixed;
  z-index: 3;
  display: none;
  opacity: 0.98;
}

.bottom-nav .bottom-nav-icon {
  color: #f6f9fc;
}

.center {
  text-align: center;
}

.hamburger-menu {
  background: #0c2e4e;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 500px;
}

.hamburger-menu-internal-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 25px;
}

.hamburger-menu-items {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #0a2540;
}

.hamburger-menu-items a {
  padding: 16px;
}

.hamburger-menu-items a:hover {
  background: #0c2e4e;
}

.nav-items a {
  text-decoration: none;
  color: unset;
  font-family: inherit;
}

.nav-items a:hover {
  color: unset;
}

.nav-items button {
  font-family: inherit;
}

.news-body {
  color: #1976d2;
  padding: 50px 0;
  align-items: center;
}

.news-header {
  padding: 20px;
  text-align: center;
}

.news-cards {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  flex-wrap: wrap;
}

.multiline-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.menu-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

.featured {
  display: flex;
  flex-direction: row;
  margin: 50px 0;
  max-width: 1500px;
  margin: auto;
  padding: 50px 0;
  justify-content: center;
}

.featured a {
  text-decoration: none;
}

.featured-left {
  margin: auto;
  background-image:
    linear-gradient(to bottom, rgba(245, 246, 252, 0.05), #0c2e4e),
    url('/public/images/news/ben-coaching-2024/photo1.jpg');
  height: 700px;
  width: 1000px;
  background-size: cover;
  color: white;
  padding: 20px;
  border-radius: 25px 0 0 25px;
  object-fit: cover;
}

.featured-right {
  display: flex;
  flex-direction: column;
  padding-left: 10px
}

.featured-right-top {
  margin: 0 0;
  background-image:
    linear-gradient(to bottom, rgba(245, 246, 252, 0.05), #0c2e4e),
    url('/public/images/news/new-president-home.jpg');
  height: 345px;
  width: 500px;
  background-size: cover;
  color: white;
  border-radius: 0 25px 0 0;
  object-fit: cover;
  padding: 20px;
}

.featured-right-bottom {
  margin: 10px 0 0 0;
  background-image:
    linear-gradient(to bottom, rgba(245, 246, 252, 0.05), #0c2e4e),
    url('/public/images/news/cash4clubs.png');
  height: 345px;
  width: 500px;
  background-size: 250px;
  color: white;
  border-radius: 0 0 25px 0;
  object-fit: cover;
  padding: 20px
}

.footer-partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.footer-partners a {
  padding: 0 10px;
}

.rounded-partner-logo {
  border-radius: 10px;
}

.footer-partners h2 {
  padding: 25px;
}

.card-links {
  text-decoration: none;
  display: flex;
}

.logo-text {
  display: flex;
  padding-right: 20px;
  color: white;
  text-align: left;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.logo-text:hover {
  text-decoration: none;
  color: unset;
}

.page-container {
  flex-wrap: wrap;
  color: #1976d2;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 15%;
  text-align: left;
}

.background-space-filler {
  height: 100%;
  width: 100%;
  background-color: #f2f2f4;
  position: absolute;
  top: 165%;
}

.footer {
  display: flex;
  background-color: #0a2540;
  flex-direction: column;
  min-height: "500px";
  color: white;
  align-items: center;
  justify-content: center;
}

.footer:nth-child(1) {
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
}

.footer-links ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.footer-links a {
  padding: 10px;
  color: white;
}

.footer-logo-text {
  display: flex;
  width: 150px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.75rem;
  padding-top: 50px;
}

.fixtures {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-dates {
  padding: 50px 10px 50px 0px;
}

.fixtures-vs-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 16px;
  flex: 1;
  text-align: center;
  position: relative;
  top: 6px;
}

.fixtures-vs-center p {
  margin: 0 0 2px 0;
}

.fixture-cards {
  display: flex;
}

.fixture-cards-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.final-score {
  position: relative;
  top: 20px;
}

.score-group {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  max-width: 85px;
}

.score-group p {
  margin: 2px;
  white-space: nowrap;
}

.score-outline {
  border: 2px solid #282c34;
  border-radius: 5px;
  padding: 10px 20px;
  background: white;
  color: #282c34;
  margin: 0;
}

.social-media {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social-media-icon {
  margin: 10px;
}

.scrolling-news {
  display: flex;
  overflow: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
}

.league-tables {
  display: flex;
  margin-top: 100px;
  text-align: left;
  flex-wrap: wrap;
}

.league-division-container {
  padding: 30px 10px 30px 10px;
}

.scrolling-news .news-body {
  padding: 0
}

.scrolling-news .news-cards {
  flex-wrap: nowrap;
}

.scrolling-news .news-cards .card-links {
  display: flex;
  white-space: initial;
  min-width: 350px;
}

.scroller::-webkit-scrollbar {
  width: 5px;
}

.scroller::-webkit-scrollbar-track {
  background: white;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

.semi-bold {
  font-weight: 600;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.carousel.slide {
  width: 100%;
}

.carousel-item img {
  height: 75vh;
  object-fit: cover;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.App-body {
  display: flex;
  flex-wrap: wrap;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Left-body {
  flex: 70%;
  color: #1976d2;
  font-size: 18px;
  justify-content: center;
  padding-right: 20px;
}

.Right-body {
  flex: 30%;
  color: #1976d2;
  font-size: 18px;
  justify-content: center;
}

.news-section {
  background: #f6f9fc;
}

p.match-score {
  font-weight: 500;
  font-size: 50px;
}

.home-quick-links {
  display: flex;
  flex-wrap: wrap;
  color: #0c2e4e;
  justify-content: center;
  margin-bottom: 50px;
}

.home-quick-links button {
  margin: 25px;
  background-color: #00d4ff;
  color: #0c2e4e;
  font-weight: 600 !important;
  border-radius: 20px;
  width: 200px;
}

.home-contact-div {
  background: #ffffff;
  display: flex;
  justify-content: center;
}

.home-contact-img {
  height: 75vh;
  object-fit: cover;
  max-width: 2000px;
}

/* do not show it on devices with max of 767 px and lower */
@media (max-width: 767px) {

  .Right-body,
  .Left-body {
    flex: 100%;
    padding-top: 50px;
  }

  .page-container {
    padding: 0 5px !important;
    overflow-x: scroll;
  }

  .article-container {
    margin: 15px 7%;
  }

  .hamburger-menu {
    width: 100vw;
  }
}

@media (max-width: 899px) {
  .carousel-inner {
    border-radius: 0;
  }

  .mobile-table th {
    padding: 15px 10px;
  }

  .mobile-table td {
    padding: 15px 10px;
  }

  .hidden-mobile {
    display: none !important;
  }

  .bottom-nav {
    display: block !important;
  }

  .footer {
    padding-top: 35px;
    padding-bottom: 50px;
  }

  .match-report-container {
    width: 90%;
  }

  .match-report {
    text-align: center;
    padding: 5% 3%;
  }

  .match-logos {
    bottom: 5vh;
  }

  .match-logos img {
    height: 90px;
    width: 90px;
  }
}

.match-report-container {
  width: 90%;
}

.player-circle-stats div {
  width: 60px;
  height: 60px;
}


@media (max-width: 1800px) {
  .featured {
    flex-wrap: wrap;
    width: 95vw;
  }

  .featured-left {
    border-radius: 0;
    max-width: 95vw;
  }

  .featured-right {
    width: 1000px;
    margin: auto;
    padding: 0;
  }

  .featured-right-top {
    border-radius: 0;
    margin-top: 10px;
    max-width: 95vw;
    width: 1000px;
    flex-wrap: wrap;
  }

  .featured-right-bottom {
    border-radius: 0;
    margin-top: 10px;
    width: 1000px;
    max-width: 95vw;
    flex-wrap: wrap;
  }
}